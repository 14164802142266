
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class CarouselTaxCard extends Vue {
        // Properties
        @Prop() private readonly title!: string;

        @Prop() private readonly band1!: string;
        @Prop() private readonly income1!: string;
        @Prop() private readonly rate1!: string;

        @Prop() private readonly band2!: string;
        @Prop() private readonly income2!: string;
        @Prop() private readonly rate2!: string;

        @Prop() private readonly band3!: string;
        @Prop() private readonly income3!: string;
        @Prop() private readonly rate3!: string;

        @Prop() private readonly band4!: string;
        @Prop() private readonly income4!: string;
        @Prop() private readonly rate4!: string;

        @Prop() private readonly band5!: string;
        @Prop() private readonly income5!: string;
        @Prop() private readonly rate5!: string;

        @Prop() private readonly cardNumber!: string;

        // Data function
        public data() {
            return {
            };
        }
    }
