
    import EconomyTaxationCards from "@/components/misc/economy_taxation_cards.vue";
    import { Component, Vue } from "vue-property-decorator";
    import { GraphUtils } from '../../ts/utils/GraphUtils';

    @Component({
        components: {
            EconomyTaxationCards,
        },
    })

    // *****************************************************************************************************************
    export default class MiscRenumerationAndTax extends Vue {
        // Fields ------------------------------------------------------------------------------------------------------
        private readonly ukTaxationGraph = {
            // tslint:disable
            traces: [
                {
                    name: 'Tax Brackets',
                    type: "bar",
                    x: [12.5, 31.25, 100, 200],
                    y: [0, 20, 40, 45],
                    width: [1, 37.5, 100, 100],
                    text: ['Below £12,500', '£12,501 to £50,000', '£50,000 to £150,000', 'Above £150,000'],
                    marker: {
                        color: ['hsl(340, 100%, 30%)', 'hsl(0, 100%, 30%)', 'hsl(60, 100%, 30%)', 'hsl(120, 100%, 30%)'],
                        size: 9
                    },
                },
                {
                    name: '%',
                    type: "scatter",
                    x: [0, 12.5, 50, 150 ],
                    y: [0, 20, 40, 45],
                    marker: {
                        color: ['hsl(340, 100%, 50%)', 'hsl(30, 100%, 50%)', 'hsl(60, 100%, 50%)', 'hsl(120, 100%, 50%)'],
                        size: 15
                    },
                    line: {
                        color: 'hsl(0, 0%, 80%)',
                        shape: 'spline',
                        width: 2
                    },
                }
            ],
            // tslint:enable
        };

        private readonly comparitiveTaxationGraph = {
            // tslint:disable
            traces: [
                {
                    name: 'UK 2020',
                    type: "scatter",
                    x: [0, 12500, 50000, 100000],
                    y: [0,    20,    40,     40],
                    marker: {
                        color: 'hsl(0, 100%, 50%)',
                        size: 15
                    },
                    line: {
                        color: 'hsl(0, 100%, 30%)',
                        width: 5
                    },
                },  
                {
                    name: 'Sweden 2019',
                    type: "scatter",
                    x: [0,  39518, 55512, 100000],
                    y: [32,    32,    52,     57],
                    marker: {
                        color: 'hsl(300, 100%, 40%)',
                        size: 9
                    },
                    line: {
                        color: 'hsl(300, 100%, 25%)',
                        width: 3
                    },
                },
                {
                    name: 'Germany (single) 2019',
                    type: "scatter",
                    x: [0, 8008, 48558, 100000],
                    y: [0,   14,    42,     42],
                    marker: {
                        color: 'hsl(210, 100%, 40%)',
                        size: 9
                    },
                    line: {
                        color: 'hsl(210, 100%, 25%)',
                        width: 3
                    },
                },
                {
                    name: 'Indonesia (resident) 2019',
                    type: "scatter",
                    x: [0, 2781, 13907, 27815, 100000],
                    y: [5,   15,    25,    30,     30],
                    marker: {
                        color: 'hsl(30, 100%, 40%)',
                        size: 9
                    },
                    line: {
                        color: 'hsl(30, 100%, 25%)',
                        width: 3
                    },
                },                
                {
                    name: 'Germany (married) 2019',
                    type: "scatter",
                    x: [0, 16015, 97115, 100000],
                    y: [0,   14,    42,     42],
                    marker: {
                        color: 'hsl(60, 100%, 40%)',
                        size: 9
                    },
                    line: {
                        color: 'hsl(60, 100%, 25%)',
                        width: 3
                    },
                },
                {
                    name: 'New Zealand 2019',
                    type: "scatter",
                    x: [   0, 7364, 24380, 35555, 100000],
                    y: [10.5, 10.5, 17.5,     30,     30],
                    marker: {
                        color: 'hsl(90, 100%, 40%)',
                        size: 9
                    },
                    line: {
                        color: 'hsl(90, 100%, 25%)',
                        width: 3
                    },
                },
                {
                    name: 'Norway 2019',
                    type: "scatter",
                    x: [0, 15025, 21152, 53170, 83074, 100000],
                    y: [0,   1.9,   4.2,  13.2,  16.2,   16.2],
                    marker: {
                        color: 'hsl(120, 100%, 40%)',
                        size: 9
                    },
                    line: {
                        color: 'hsl(120, 100%, 25%)',
                        width: 3
                    },
                }, 
            ],
            // tslint:enable
        };

        private readonly taxAvoidanceGraph = {
            // tslint:disable
            traces: [
                {
                    name: 'Company',
                    type: "bar",
                    x: ["Facebook", "Amazon", "Google", "Netflix", "Apple"],
                    y: [10.2, 12.7, 15.8, 15.8, 17.1],
                    marker: {
                        color: ['hsl(0, 100%, 50%)', 'hsl(14, 100%, 40%)', 'hsl(29, 100%, 35%)', 'hsl(29, 100%, 30%)', 'hsl(36, 100%, 25%)'],
                    },
                }
            ],
            // tslint:enable
        };

        private readonly salariesInUk = {
            // tslint:disable
            traces: [
                {
                    type:           "bar",
                    name:           "£ Minimum Pay",
                    orientation:    'h',
                    x: [16000,
                        23553,
                        22000,
                        18000,
                        22000,
                        21000,
                        21000,
                        22000,
                        19971,
                        21000,
                        39693,
                        18000,
                        51330,
                        21000,
                        25000,
                        40037,
                        30000,
                        30000,
                        39000,
                        55000,
                        26000,
                        45000,
                        35000,
                        74000],
                    y: ["Soldier",
                        "Junior<br>Doctor",
                        "Fire<br>Fighter",
                        "Software<br>Tester",
                        "Social<br>Worker",
                        "Paramedic",
                        "Nurse",
                        "Electrician",
                        "Police<br>Constable",
                        "School<br>Teacher",
                        "Police<br>Sergeant",
                        "Plumber",
                        "Police<br>Inspector",
                        "Psychologist",
                        "Data<br>Scientist",
                        "Specialty<br>Doctor",
                        "Solicitor",
                        "Dentist",
                        "Marketing<br>Director",
                        "Tube Driver",
                        "Air Traffic<br>Controllers",
                        "Judges &<br>Magistrates",
                        "Air Pilots &<br>Flight Engineers",
                        "Member of<br>Parliment"],
                    marker: {
                        color: 'hsl(210, 100%, 45%)'
                    },
                },
                {
                    type:           "bar",
                    name:           "£ Extra Pay",
                    orientation:    'h',
                    x: [13000,
                        10142,
                        13000,
                        18000,
                        15000,
                        17000,
                        17000,
                        16000,
                        18411,
                        20000,
                        3441,
                        27000,
                        4182,
                        35000,
                        35000,
                        34624,
                        60000,
                        67000,
                        61000,
                        48000,
                        78000,
                        63000,
                        83000,
                        46000],
                    y: ["Soldier",
                        "Junior<br>Doctor",
                        "Fire<br>Fighter",
                        "Software<br>Tester",
                        "Social<br>Worker",
                        "Paramedic",
                        "Nurse",
                        "Electrician",
                        "Police<br>Constable",
                        "School<br>Teacher",
                        "Police<br>Sergeant",
                        "Plumber",
                        "Police<br>Inspector",
                        "Psychologist",
                        "Data<br>Scientist",
                        "Specialty<br>Doctor",
                        "Solicitor",
                        "Dentist",
                        "Marketing<br>Director", 
                        "Tube Driver",
                        "Air Traffic<br>Controllers", 
                        "Judges &<br>Magistrates",
                        "Air Pilots &<br>Flight Engineers", 
                        "Member of<br>Parliment"],
                    marker: {
                        color: 'hsl(30, 100%, 50%)'
                    },
                }
            ],
            // tslint:enable
        };

        private readonly ukPolitiansSalaryByYear = {
            // tslint:disable
            traces: [
                {
                    name: 'Member of Parliment Minimum Salary',
                    type: "scatter",
                    x: ["1996", "1998", "2000", "2002", "2004", "2006", "2008", "2010", "2012", "2014", "2016", "2018", "2020"],
                    y: [ 34085,  45066,  48371,  55118,  57485,  59686,  60277,  61820,  61820,  66396,  74962,  77379,  81931],
                    marker: {
                        color: 'hsl(0, 100%, 65%)',
                        size: 15
                    },
                    line: {
                        shape: 'spline',
                        color: 'hsl(0, 100%, 50%)',
                        width: 3
                    },
                },
                {
                    name: 'Average Salary',
                    type: "scatter",
                    x: ["1996", "1998", "2000", "2002", "2004", "2006", "2008", "2010", "2012", "2014", "2016", "2018", "2020"],
                    y: [ 25688,  26312,  27404,  28912,  30212,  30680,  31304,  31044,  29536,  29068,  29952,  30108,  30368],
                    marker: {
                        color: 'hsl(240, 100%, 65%)',
                        size: 15
                    },
                    line: {
                        shape: 'spline',
                        color: 'hsl(240, 100%, 50%)',
                        width: 3
                    },
                }
            ],
            // tslint:enable
        };

        // Methods -----------------------------------------------------------------------------------------------------
        private get createSalaryLayout(): object {
            // tslint:disable
            const layout: any       = GraphUtils.createLayout("(£) Salary", "", 120, 48);
            layout["barmode"]       = 'stack';
            layout["height"]        = 1024;
            layout["font"]["size"]  = 12;
            layout["showlegend"]    = true;
	        layout["legend"]        = { x: 1.0, y: 0.0, xanchor: 'right', orientation: "v" };
            // tslint:enable
            return layout;
        }

        private get createVsSalaryLayout(): object {
            // tslint:disable
            const layout: any       = GraphUtils.createLayout("", "(£) Salary", 72, 32);
            layout["showlegend"]    = true;
            layout["legend"]        = { x: 1.0, y: 0.0, xanchor: 'right', orientation: "v" };
            // tslint:enable
            return layout;
        }

        private get createUKTaxLayout(): object {
            // tslint:disable
            return GraphUtils.createLayout("(£) Income", "Tax Percentage", 72, 48);
            // tslint:enable
        }

        private get createComparativeTaxLayout(): object {
            // tslint:disable
            const layout: any       = GraphUtils.createLayout("(£) Income", "Tax Percentage", 72, 48);
            layout["showlegend"]    = true;
            layout["legend"]        = { x: 1.0, y: 0.0, xanchor: 'right', orientation: "v" };
            // tslint:enable
            return layout;
        }

        private get createTaxAvoidanceLayout(): object {
            // tslint:disable
            return GraphUtils.createLayout("", "Percentage of Profit Paid", 72, 16);
            // tslint:enable
        }

        private data() {
            return {
                economyIconPathAndName:
                    require("@/assets/images/icon_economy.png"),

                salaryIconPathAndName:
                    require("@/assets/images/icon_salary.png"),
            };
        }
    }
