
    import CarouselTaxCard from "@/components/carousel_cards/carousel_tax_card.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            CarouselTaxCard
        },
    })

    // *****************************************************************************************************************
    export default class EconomyTaxationCards extends Vue {
    }
